import { Identity } from '@polymeshassociation/polymesh-sdk/types';
import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';

export interface AccountStatus {
  status: 'Assigned' | 'Unassigned';
  address: string;
  did?: string;
}

export const getAccountStatuses = async (
  allIdentities: (Identity | null)[],
  allAccountsWithMeta: InjectedAccountWithMeta[]
): Promise<Record<string, AccountStatus>> => {
  try {
    // Get all primary accounts for identities
    const identityAccounts = await Promise.all(
      allIdentities
        .filter((id): id is Identity => id !== null)
        .map(async (id) => {
          const primaryAccount = await id.getPrimaryAccount();
          return {
            did: id.did,
            primaryAccount: primaryAccount.account.address,
          };
        })
    );

    // Create status mapping for each account
    const accountStatuses: Record<string, AccountStatus> = {};
    allAccountsWithMeta.forEach((account) => {
      const accountName = String(account.meta.name);
      const assignedIdentity = identityAccounts.find(
        (idAccount) => idAccount.primaryAccount === account.address
      );

      accountStatuses[accountName] = {
        status: assignedIdentity ? 'Assigned' : 'Unassigned',
        address: account.address,
        did: assignedIdentity?.did,
      };
    });

    return accountStatuses;
  } catch (error) {
    console.error('Error in getAccountStatuses:', error);
    return {};
  }
};