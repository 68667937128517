export const POYLMESH_EXTN_NAME = 'polywallet';
export const APP_NAME = 'TokenTraxx';
export const TESTNET_CHAIN = 99999; // this is set by us for our purpose; there is no chain ID with this type
export const MAINNET_CHAIN = 999; // this is set by us for our purpose; there is no chain ID with this type

export const TICKER = '';

// MAINNET
const prodArtistList = [
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1695133330-evan-430x430-profile.png',
    artistName: 'Evan Malamud',
    // id: '65d894224fea2479bd67df66',
    id: '0xeff44c144dc22f9b0b631c051ce9b3810617761112f5a81d49ede0c12c1804af',
    numOfCollections: 1,
    slug: 'ROMEOXJULIET',
    portfolio: 'traxx x evan',
    nftPriceInPOLYX: 'free',
    nftPriceInDollar: 'free',
  },
  {
    artistHandle: null,
    artistImage:
      'https://arweave.net/Le2AKzaawmogVIS01u4LUfMSJcYU53fh5OqiGwg5p3U',
    artistName: 'Josh Savage',
    collectionCreationTxnHash:
      '0x4e57a83678b3ea314ba81565cb866de86c2e5b7b9b1faed77daadff44d6f37cc',
    id: '0xeff44c144dc22f9b0b631c051ce9b3810617761112f5a81d49ede0c12c1804af',
    custodian:
      '0x3b37c2e1b35ced6d62e336c2442df9fa4eacdf69e296c992f1f6916da64b605e',
    numOfCollections: 1,
    slug: 'SOMEONE2MISS',
    portfolio: 'josh x polymesh',
    nftPriceInDollar: '1000',
    assetId: '0x7b696706618b850481c4805c9a5f0bc4',
  },
];

// TESTNET
const testArtistList = [
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1708607354-dall-e-2024-02-21-14-29-12-imagine-a-profile-picture-for-kane-fifita-a-dynamic-entrepreneur-in-the-e-gaming-industry-kane-is-the-ceo-and-co-founder-of-shadow-gaming-a-revolut.jpg',
    artistName: 'Kurt Pittman',
    // id: '65d894034fea2479bd67df4b',
    id: '0x944bae358fbb4119243c66fbd0856e9f79d8ec328877cac5b054488b43b46fc2',
    numOfCollections: 1,
    slug: 'GOING',
    portfolio: 'TT X ARTIST',
    nftPriceInPOLYX: '2',
    nftPriceInDollar: '10',
  },
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1708612550-dall-e-2024-02-22-14-35-10-create-a-stunning-cover-image-for-oisin-murphys-website-that-encapsulates-the-fusion-of-traditional-irish-music-with-the-vibrancy-of-contemporary-cul.jpg',
    artistName: 'Oisin Murphy',
    // id: '65d894224fea2479bd67df66',
    id: '0x944bae358fbb4119243c66fbd0856e9f79d8ec328877cac5b054488b43b46fc2',
    numOfCollections: 1,
    slug: 'USOPP',
    portfolio: 'TT X ARTIST',
    nftPriceInPOLYX: '2',
    nftPriceInDollar: '10',
  },
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1708612550-dall-e-2024-02-22-14-35-10-create-a-stunning-cover-image-for-oisin-murphys-website-that-encapsulates-the-fusion-of-traditional-irish-music-with-the-vibrancy-of-contemporary-cul.jpg',
    artistName: 'Evan',
    // id: '65d894224fea2479bd67df66',
    id: '0x63efd1ef9b0d3dd0313e5cb33cd462dc708c4b647c884cd52c793f8726392e86',
    numOfCollections: 1,
    slug: 'LOVENFT',
    portfolio: 'TT X ARTIST',
    nftPriceInPOLYX: '2',
    nftPriceInDollar: '10',
  },
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1708607354-dall-e-2024-02-21-14-29-12-imagine-a-profile-picture-for-kane-fifita-a-dynamic-entrepreneur-in-the-e-gaming-industry-kane-is-the-ceo-and-co-founder-of-shadow-gaming-a-revolut.jpg',
    artistName: 'Luffy',
    // id: '65d894224fea2479bd67df66',
    id: '0x6c3133de3d9cdaac3738af5914097867f84c51674672df8c5b759b1600479441',
    numOfCollections: 1,
    slug: 'LIONNFT',
    portfolio: 'tt custody',
    nftPriceInPOLYX: '2',
    nftPriceInDollar: '10',
  },
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1708607354-dall-e-2024-02-21-14-29-12-imagine-a-profile-picture-for-kane-fifita-a-dynamic-entrepreneur-in-the-e-gaming-industry-kane-is-the-ceo-and-co-founder-of-shadow-gaming-a-revolut.jpg',
    artistName: 'Luke',
    // id: '65d894224fea2479bd67df66',
    id: '0x45e6fcc720200ca7bf18a3a48477de6633697c17366212e7a579fd331c09476d',
    custodian:
      '0xd88fa73ec13ac46ba953eaadf7c09512977f5f5fefac9e7129ccfde9eff285c2',
    numOfCollections: 1,
    slug: 'GENESIS',
    portfolio: 'Fiat Test',
    nftPriceInPOLYX: '2',
    nftPriceInDollar: '10',
  },
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1708607354-dall-e-2024-02-21-14-29-12-imagine-a-profile-picture-for-kane-fifita-a-dynamic-entrepreneur-in-the-e-gaming-industry-kane-is-the-ceo-and-co-founder-of-shadow-gaming-a-revolut.jpg',
    artistName: 'Solis',
    collectionCreationTxnHash:
      '0x3939868469bb2d2da4c0db7d80fb8dbcb6f9a757f9ff8179dea23b7025f59acf',
    id: '0x63efd1ef9b0d3dd0313e5cb33cd462dc708c4b647c884cd52c793f8726392e86',
    custodian:
      '0xd88fa73ec13ac46ba953eaadf7c09512977f5f5fefac9e7129ccfde9eff285c2',
    numOfCollections: 1,
    slug: 'ECLIPSE',
    portfolio: 'tt x eclipse',
    nftPriceInDollar: '0.5',
    deprecatedAssetId: '0xc2f8a35d95ff82cb93bdd964f321bca3',
    assetId: 'c2f8a35d-95ff-82cb-93bd-d964f321bca3',
  },
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1708607354-dall-e-2024-02-21-14-29-12-imagine-a-profile-picture-for-kane-fifita-a-dynamic-entrepreneur-in-the-e-gaming-industry-kane-is-the-ceo-and-co-founder-of-shadow-gaming-a-revolut.jpg',
    artistName: 'Josh Savage',
    collectionCreationTxnHash:
      '0x7e319d91c70f8d9fe13df8ae54a038df02562c7e37ae1bd4a8c8fd4af5730d0e',
    id: '0x63efd1ef9b0d3dd0313e5cb33cd462dc708c4b647c884cd52c793f8726392e86',
    custodian:
      '0xd88fa73ec13ac46ba953eaadf7c09512977f5f5fefac9e7129ccfde9eff285c2',
    numOfCollections: 1,
    slug: 'SOMEONE2MISS',
    portfolio: 'josh x polymesh',
    nftPriceInDollar: '1000',
    deprecatedAssetId: '0xd38b94b95ec1845ab423b900eab4fb8b',
    assetId: '0xd38b94b95ec1845ab423b900eab4fb8b',
  },
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1708607354-dall-e-2024-02-21-14-29-12-imagine-a-profile-picture-for-kane-fifita-a-dynamic-entrepreneur-in-the-e-gaming-industry-kane-is-the-ceo-and-co-founder-of-shadow-gaming-a-revolut.jpg',
    artistName: 'Josh Savage',
    collectionCreationTxnHash:
      '0x7e319d91c70f8d9fe13df8ae54a038df02562c7e37ae1bd4a8c8fd4af5730d0e',
    id: '0x63efd1ef9b0d3dd0313e5cb33cd462dc708c4b647c884cd52c793f8726392e86',
    custodian:
      '0xd88fa73ec13ac46ba953eaadf7c09512977f5f5fefac9e7129ccfde9eff285c2',
    numOfCollections: 1,
    slug: 'SOMEONE2MISS',
    portfolio: 'josh x polymesh',
    nftPriceInDollar: '1000',
    assetId: '0xd38b94b95ec1845ab423b900eab4fb8b',
  },
  {
    artistHandle: null,
    artistImage:
      'https://arweave.net/Le2AKzaawmogVIS01u4LUfMSJcYU53fh5OqiGwg5p3U',
    artistName: 'Josh Savage',
    collectionCreationTxnHash:
      '0x9f613aa23c740a96c2f189d8fcfd6e7b1dc2236c755cf78769c0945cca567314',
    id: '0xd133ed661763791b0a6c623e5414c50a61056aef22715c4332425b20f8a067d8',
    custodian:
      '0xd88fa73ec13ac46ba953eaadf7c09512977f5f5fefac9e7129ccfde9eff285c2',
    numOfCollections: 1,
    slug: 'SOME12MISS',
    portfolio: 'josh x polymesh',
    nftPriceInDollar: '1000',
    assetId: '0x8f08067d4f6a818dbf3e8f7d34f5b631',
  },
];

export const whitelistedArtistList =
  process.env.NEXT_PUBLIC_POLYMESH_ENV === 'prod'
    ? prodArtistList
    : testArtistList;
export const maxPurchaseLimitPerUser =
  process.env.NEXT_PUBLIC_POLYMESH_ENV === 'prod' ? 5 : 2;

export const randomSubStringInt =
  process.env.NEXT_PUBLIC_POLYMESH_ENV === 'prod' ? 6 : 5;

export const polymeshPortalRedirectUrl =
  process.env.NEXT_PUBLIC_POLYMESH_ENV === 'prod'
    ? 'https://portal.polymesh.network/portfolio'
    : `https://portal.polymesh.live/portfolio`;

export const getAssetIdFromTicker = (ticker: string) => {
  const nftDetails =
    process.env.NEXT_PUBLIC_POLYMESH_ENV === 'prod'
      ? [
          {
            ticker: 'SOMEONE2MISS',
            assetId: '7b696706-618b-8504-81c4-805c9a5f0bc4',
          },
        ]
      : [
          {ticker: 'ECLIPSE', assetId: 'c2f8a35d-95ff-82cb-93bd-d964f321bca3'},
          {
            ticker: 'SOMEONE2MISS',
            assetId: 'd38b94b9-5ec1-845a-b423-b900eab4fb8b',
          },
          {
            ticker: 'SOME12MISS',
            assetId: '8f08067d-4f6a-818d-bf3e8f7d34f5b631',
          },
        ];

  const getSpecificNft = nftDetails.find((nft) => {
    return nft.ticker === ticker;
  });

  return getSpecificNft && getSpecificNft.assetId;
};

// Not yet used. Need to delete it
export const isVenueDeprecated =
  process.env.NEXT_PUBLIC_POLYMESH_ENV === 'prod';
