import {Pressable} from '../pressable';
import {Box} from '../box/index.web';
import {Typography} from '../typography/index.web';

interface AccountItemProps {
  name: string;
  address: string;
  isAssigned: boolean;
  onClick?: () => void;
}

export const AccountItem: React.FC<AccountItemProps> = ({
  name,
  address,
  isAssigned,
  onClick,
}) => {
  const truncateString = (str: string) => {
    if (str.length <= 10) return str;
    return `${str.slice(0, 5)}...${str.slice(-5)}`;
  };

  return (
    <Pressable onPress={isAssigned ? onClick : undefined}>
      <Box
        width="100%"
        borderBottomColor="grey24"
        borderBottomWidth="one"
        borderBottomStyle="solid"
        padding="three"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        opacity={isAssigned ? 1 : 0.5}
        cursor={isAssigned ? 'pointer' : 'not-allowed'}
      >
        <Box marginRight="eighteen">
          <div>
            <Typography color="textDefault" fontSize="m">
              {name}
            </Typography>
          </div>
          <div>
            <Typography color="textDefault" opacity={0.9}>
              {truncateString(address)}
            </Typography>
          </div>
        </Box>
      </Box>
    </Pressable>
  );
};
